import fetchData from './fetchData'
import {parseJSON, checkHttpStatus} from '../shared/utils'
import config from '../config/index'

async function getListChatbot(payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=chatlist`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

async function sendInvite(payload) {
  return fetchData(`${config.api.baseUrl}?class=maxbonus&action=chaturl`, 'POST', payload)
    .then(parseJSON)
    .then(checkHttpStatus);
}

async function getListOrderType(payload) {
    return fetchData(`${config.api.baseUrl}?class=maxbonus&action=ordertypes`, 'POST', payload)
        .then(parseJSON)
        .then(checkHttpStatus);
}

export {
    getListChatbot,
    sendInvite,
    getListOrderType
}