import React, {useState, useCallback, useEffect} from 'react';
import MainNavBar from '../../../../shared/components/NavBar';
import QueueAnim from 'rc-queue-anim';
import {Card, WhiteSpace, WingBlank, Button, Accordion, List, Toast, Checkbox} from "antd-mobile-v2";
import {checkDetail, checkList, returnCheck, returnCheckV2} from "../../../../api/check";

import moment from 'moment';
import _ from "lodash";

const Item = List.Item;
const Brief = Item.Brief;
const CheckboxItem = Checkbox.CheckboxItem;

const ReturnScreen = ((props) => {

    const useApiV2 = localStorage.getItem('useApiV2') === 'true';

    const [listCheck, setListCheck] = useState([]);
    const [dataDetail, setDataDetail] = useState([]);
    const [check, setCheck] = useState({
        body:
            [],
        head: {
            check:0,
            customer: JSON.parse(localStorage.getItem('client')).s_token,
            kkm:"",
            pin:"",
            point: localStorage.getItem('point'),
            time: moment().format("DD.MM.YYYY HH:mm:ss"),
            lscheck:"0"
        },
        total:{
            amount: 0,
            discount:"0.00",
            action:"",
            paybonus:"0",
            bonus:"0",
            paycash:"0",
            paynocash:"0"
        }
    });

    useEffect(() => {
        Toast.loading('', 30);
        checkList({s_token: JSON.parse(localStorage.getItem('client')).s_token, s_customer_token: JSON.parse(localStorage.getItem('client')).s_customer_token})
            .then(({ result }) => {
                //console.log(result, _.filter(result, {n_return: '0'}))
                setListCheck(_.filter(result, {n_return: '0'}))
                Toast.hide();
            })
            .catch( () => {
                Toast.hide();
            })
    },[]);

    const showDetail = useCallback((e) => {
        Toast.loading('', 30);
        checkDetail({idnum: e})
            .then(({ result }) => {
                setDataDetail(result)
                let active_row = _.find(listCheck, { 'idnum': e});

                setCheck({
                    body:
                        [],
                    head: {
                        check: active_row.s_number,
                        customer: JSON.parse(localStorage.getItem('client')).s_token,
                        kkm: active_row.s_kkm,
                        pin:"",
                        point: localStorage.getItem('point'),
                        time: moment().format("DD.MM.YYYY HH:mm:ss"),
                        lscheck:"0"
                    },
                    total:{
                        amount: active_row.n_amount,
                        discount: active_row.n_discount,
                        action: "0",
                        paybonus: active_row.n_paybonus,
                        bonus: active_row.n_bonus,
                        paycash: active_row.n_paycash,
                        paynocash: active_row.n_paynocash
                    }
                });

                Toast.hide();
            })
            .catch( () => {
                Toast.hide();
            })
    }, [listCheck]);

    const addGoodsOnCheck = useCallback((e, goods) => {
        let newGoods = check.body;
        let res = _.filter(newGoods, function (item){
            return item.goods !== goods.s_identity;
        });

        res = e ? [...res, {
            goods: goods.s_identity,
            quantity: goods.n_quantity,
            amount: goods.n_amount,
            bonus: goods.n_bonus,
            action: "0",
            paybonus:"0",
            nogoods:"0",
            discount: goods.n_discount
        }] : res;

        setCheck({...check, body: res});
        //console.log(check, e, newGoods)
    }, [check]);

    const returnCheckQ = useCallback((e) => {
        let myJSON = JSON.stringify(check);

        // console.log(check);
        
        if (useApiV2) {

            myJSON = check.body.length === 0 
            ? {
                code: check.head.check
            }
            : {
                code: check.head.check, 
                items: check.body.map((item) => {
                    return {
                        code: item.goods,
                        price: item.amount,
                        qty: item.quantity
                    }
                })
            }

            returnCheckV2(myJSON).then(({data}) => {
                console.log(data)
                Toast.info('Возврат успешно оформлен', 1);
                window.location.href = `${process.env.PUBLIC_URL}/client`;
            })
            .catch( () => {
                // Toast.info(message, 1);
                Toast.hide();
            })
        } else {
            returnCheck({check: myJSON})
            .then(({ result }) => {
                    console.log(result)
                    Toast.info('Возврат успешно оформлен', 1);
                    window.location.href = `${process.env.PUBLIC_URL}/client`;
                })
                .catch( () => {
                    Toast.hide();
                })
        } 


       
    }, [check]);

    return (
        <>
            <MainNavBar {...props} backWay='client' text="Возврат товара"/>
            <QueueAnim delay={300} className="queue-simple">

                <Accordion accordion className="my-accordion" onChange={(e) => e && showDetail(e)}>
                    {listCheck.map((item, key) =>
                        <Accordion.Panel key={item.idnum} header={
                            <>
                                {`Чек от ${moment(item.d_time).format('DD.MM.YYYY HH:mm')}`}
                            </>
                        } className="pad">
                            <WingBlank key={key}>
                                <WhiteSpace size='xl' />
                                <Card>
                                    <Card.Header
                                        title={`№${item.s_number}`}
                                        extra={check.body.length > 0 && <Button onClick={(e) => returnCheckQ(e)}>Возврат</Button>}
                                    />
                                        <List renderHeader={() => 'Товары'} className="my-list">
                                            {dataDetail.map((goods) =>
                                                <CheckboxItem key={goods.idnum} disabled={Number(goods.n_quantity) <= 0} defaultChecked={false} onChange={(e) => addGoodsOnCheck(e.target.checked, goods)}>
                                                    <Item key={goods.idnum} style={{textDecoration: Number(goods.n_quantity) <= 0 && 'line-through'}} extra={Number(goods.n_quantity) > 0 && `${Number(goods.n_quantity)} шт`}>{goods.s_goods_name}<Brief>{Number(goods.n_quantity) > 0 && `${goods.n_amount} ₽`} </Brief></Item>
                                                </CheckboxItem>
                                            )}
                                        </List>
                                </Card>
                                <WhiteSpace size='xl' />
                            </WingBlank>
                        </Accordion.Panel>
                    )}
                </Accordion>

            </QueueAnim>
        </>
    )
});

export default ReturnScreen