import React, {useState, useCallback, useEffect}  from 'react';
import {
    Flex,
    WhiteSpace,
    Card,
    PullToRefresh,
    WingBlank,
    Icon,
    Toast,
    Popover,
    NoticeBar,
    Button
} from 'antd-mobile-v2';
import QueueAnim from 'rc-queue-anim';
import {Link} from 'react-router-dom';
import MainNavBar from "../../shared/components/NavBar";
import {findUser, findUserApiV2} from "../../api/find";

import { FaViber, FaVk, FaTelegramPlane,FaTimesCircle } from 'react-icons/fa';
import {getListChatbot, getListOrderType, sendInvite as requestSendInvite} from "../../api/client";
import CheckScreen from "./screens/Goods/screens/Check";
import { Dialog, Divider, Grid, Modal, Selector } from 'antd-mobile';

const Item = Popover.Item;

const initChatBots = [
    {
        name: 'Viber',
        icon: <FaViber style={{color: '#F4743D'}} />,
        idnum: '1'
    },
    {
        name: 'Вконтакте',
        icon: <FaVk style={{color: '#F4743D'}} />,
        idnum: '2'
    },
    {
        name: 'Telegram',
        icon: <FaTelegramPlane style={{color: '#F4743D'}} />,
        idnum: '3'
    }
];

function merge(a, b, prop){
    let reduced =  a.filter( aitem => ! b.find ( bitem => aitem[prop] === bitem[prop]) );
    return reduced.concat(b);
}

const ClientScreen = ((props) => {

    const useApiV2 = localStorage.getItem('useApiV2') === 'true';
    const [client, setClient] = useState(localStorage.getItem('client') ? JSON.parse(localStorage.getItem('client')) : {});
    const [showPopover, setShowPopover] = useState(false);
    const [chatBots, setChatBots] = useState([]);
    const [orderTypes, setOrderTypes] = useState([]);
    const [activeOrderType, setActiveOrderType] = useState(localStorage.getItem('orderType'));
    const [goods, setGoods] = useState(props.location.state ? props.location.state.goods : {});

    const fetchData = useCallback(() => {
        let {s_phone} = client;
        
        useApiV2 
            ?
                findUserApiV2(s_phone,{phone: s_phone}).then(( {data} ) => {
                    // console.log(data)
                    const clientApi = {
                        s_phone: data.phone,
                        is_chatbot: !!data.exists_chatbot ? 1: 0,
                        s_name1: data.last_name,
                        s_name2: data.first_name,
                        s_name3: data.middle_name,
                        d_birthday: "", // Др нет
                        n_balance: data.total_bonus,
                        s_loyalty_level: 0, // Тоже нет
                        s_cashier_info: data.cashier_info,
                        s_customer_token: data.token
                    }
            
                    setClient(clientApi)
                    console.log('has refreshed');
                    localStorage.setItem('client', JSON.stringify(clientApi));
            
                    //window.location.href = `${process.env.PUBLIC_URL}/client`;
                })
                .catch( () => {
                    Toast.info('Ошибка обновления информации', 1);
                })
            :
                findUser({phone: s_phone})
                .then(({ result }) => {
                    let res = result[0];
                    setClient(res);
                    console.log('has refreshed');
                    localStorage.setItem('client', JSON.stringify(res));
                })
                .catch( () => {
                    Toast.info('Ошибка обновления информации', 1);
                });
    }, [client]);

    const updateClient = useCallback(() => {
        fetchData()
    },[fetchData]);

    const sendInvite = useCallback((e) => {

        let payload = {
            s_phone: client.s_phone,
            n_chatbot: e.key
        };

        requestSendInvite(payload).then(({ result }) => {
            console.log(result);
            Toast.info('Приглашение успешно отправлено', 1);
        })
            .catch( () => {
                Toast.info('Ошибка отправки приглашения', 1);
            });
        setShowPopover(false);
    },[client]);

    useEffect(() => {
        getListChatbot().then(({ result }) => {
            setChatBots(merge(result, initChatBots, "idnum"));
        })
            .catch( () => {console.log('error')});
        
        if (!!client.s_cashier_info && client.s_cashier_info.length > 0) 
        {
            Modal.show({
                content: client.s_cashier_info.map((item) => 
                    <div style={{whiteSpace: "pre-wrap"}}>{`${item.num}) ${item.text}`}</div>),
                closeOnMaskClick: true,
            }) 
        }
           
        fetchData()
    },[]);

    useEffect(() => {
        getListOrderType().then(({ result }) => {
            setOrderTypes(result)
            // setChatBots(merge(result, initChatBots, "idnum"));
        })
            .catch( () => {console.log('error')});
    },[]);

    const changeOrderType = useCallback((e) => {
        localStorage.setItem('orderType', e)
        setActiveOrderType(e)
    },[activeOrderType]);

    return (
        <>
            <div className="main-block client">
                <MainNavBar {...props} text="Чек клиента" backWay='main' goods={{}} rightContent={
                    useApiV2 && 
                    <Button
                        size='small'
                        onClick={() =>
                            Dialog.show({
                                closeOnMaskClick: true,
                                content:<Selector
                                options={orderTypes}
                                defaultValue={activeOrderType}
                                onChange={(arr, _) => changeOrderType(arr)}
                            />})
                        }
                    >
                        Тип заказа
                    </Button> 
                }/>
                <WingBlank style={{textAlign: 'center'}}>
                </WingBlank>
                {Number(client.is_chatbot) !== 1 &&
                <NoticeBar marqueeProps={{loop: true, style: {padding: '0 7.5px'}}} onClick={() => setShowPopover(true)}>
                    Вы можете отправить клиенту приглашение в чат-бот. Для этого нажмите тут, либо на карточке клиента в графе
                    отсутствия чат-бота.
                </NoticeBar>
                }
                <WhiteSpace style={{paddingTop: 100}} />
                <Flex key='a' direction="column" justify="around" alignContent="start">
                    <PullToRefresh
                        damping={60}
                        style={{
                            height: '100%',
                            overflow: 'auto',
                        }}
                        indicator={{ activate: 'Отпустите для обновления', finish: 'Данные обновлены' }}
                        direction='down'
                        onRefresh={() => updateClient()}
                    >
                        <WingBlank>
                            <QueueAnim delay={300} className="queue-simple">
                                <Card key='a' style={{minWidth: '220px'}}>
                                    <b>
                                    <Card.Header
                                        title={`${client.s_name1 && client.s_name1} ${client.s_name2 && client.s_name2} ${client.s_name3 && client.s_name3}`}
                                    />
                                    </b>
                                    <Card.Body>
                                        <Grid columns={1} gap={8}>
                                            <Grid.Item>
                                                <Flex justify="around" alignContent="start" align="start">
                                                    <Flex.Item>
                                                        <span>{client.s_phone}</span>
                                                    </Flex.Item>
                                                    <Flex.Item>
                                                        <span>{client.d_birthday}</span>
                                                    </Flex.Item>
                                                </Flex>
                                            </Grid.Item>
                                            <Grid.Item>
                                                {client.s_loyalty_level.length > 0 &&
                                                    <Flex justify="around" alignContent="start" align="start">
                                                        <Flex.Item>
                                                            <span style={{color: '#F4743D'}}>{client.s_loyalty_level}</span>
                                                        </Flex.Item>
                                                    </Flex>
                                                }
                                                {client.n_spend !== undefined &&
                                                <Flex justify="around" alignContent="start" align="start">
                                                    <Flex.Item>
                                                        <span>Сумма трат:</span>
                                                    </Flex.Item>
                                                    <Flex.Item>
                                                        <span style={{color: '#F4743D'}}>{Number(client.n_spend)}</span>
                                                    </Flex.Item>
                                                </Flex>
                                                }
                                            </Grid.Item>
                                        </Grid>
                                    </Card.Body>
                                    <Card.Footer
                                        content={
                                            <Flex direction="column" justify="around" alignContent="start" align="start">
                                                <Flex.Item>
                                                    Бонусы
                                                </Flex.Item>
                                                <Flex.Item>
                                                    <span style={{color: '#F4743D'}}>{Number(client.n_balance)}</span>
                                                </Flex.Item>
                                            </Flex>
                                        }
                                        extra={
                                            <Flex direction="column" justify="around" alignContent="start" align="start">
                                                <Flex.Item>
                                                    Чат-бот
                                                </Flex.Item>
                                                <Flex.Item>
                                                    <b><span style={{color: '#000'}}>
                                                        {Number(client.is_chatbot) === 1
                                                            ? <span>Имеется</span>
                                                            :
                                                            <Popover mask
                                                                     overlayClassName="fortest"
                                                                     overlayStyle={{ color: 'currentColor' }}
                                                                     visible={showPopover}
                                                                     overlay={
                                                                         <>
                                                                             <div style={{padding: 10}}>
                                                                                Отправить приглашение <FaTimesCircle style={{color: '#F4743D'}} onClick={() => setShowPopover(false)} />
                                                                             </div>
                                                                             {chatBots.map(item =>
                                                                                 <Item key={item.idnum} value={item.idnum} icon={item.icon}>
                                                                                     {item.name}
                                                                                 </Item>
                                                                             )}
                                                                         </>
                                                                     }
                                                                     align={{
                                                                         overflow: { adjustY: 0, adjustX: 0 },
                                                                         offset: [-10, 0],
                                                                     }}
                                                                     onVisibleChange={e => setShowPopover(e)}
                                                                     onSelect={e => sendInvite(e)}
                                                            >
                                                                <span>Отсутствует</span>
                                                            </Popover>
                                                        }
                                                    </span></b>
                                                </Flex.Item>
                                            </Flex>
                                        } />
                                </Card>
                            </QueueAnim>
                        </WingBlank>
                    </PullToRefresh>
                    <WhiteSpace style={{paddingTop: 40}} />

                    <div className="action-button">
                        <Link to={{
                            pathname: `${process.env.PUBLIC_URL}/client/addGoods`,
                            state: {
                                goods: goods,
                                hasGoods: true
                            }}}
                        >
                            <Icon type="plus" size="xl"/>
                        </Link>
                    </div>
                </Flex>
            </div>
            <CheckScreen {...props} goods={goods} setGoods={setGoods}/>
            <WhiteSpace style={{paddingTop: 60}} />
        </>
    )
});

export default ClientScreen